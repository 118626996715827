@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";

.Canvas {
  @include CommonModalStyles;
}

.Wrapper {
  padding: 50px 0;
  height: 100%;
  position: static;

  .Container {
    @include CommonStylesOfModalContainer;
    overflow: hidden;
    //border: 2px dashed orange;
    max-width: 900px !important;

    .TitleBlock {
      width: 100%;
      @include FLexRow;
      justify-content: space-between;
      padding-right: 10px;

      .Title {
        @include FontMedium(30, 36);
      }
    }

    .Table {
      tbody {
        tr {
          border: none !important;

          .TableCell {
            padding: 10px;
            @include FontRegular(20, 26);
            font-size: 1.1rem;
            color: rgba(145, 153, 173, 1);
            border: none !important;
            word-wrap: normal;
            vertical-align: top;
            white-space: nowrap;

            &:nth-child(2) {
              word-break: break-all;
              white-space: wrap;

              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
