.TextFieldPassword {
  width: 100%;
  position: relative;

  > div {
    width: 100%;
    border-radius: 17px;


    > input {
      border: none;
      background: none !important;
      border-radius: 19px;
      font-size: 16px;
      line-height: 20px;
      color: rgb(255, 255, 255);
      padding: 14px 22px;
    }

    > fieldset {
      color: rgba(72, 80, 110, 1);
      border-color: rgba(72, 80, 110, 1);
      padding-left: 25px;

      > legend {
        color:rgba(72, 80, 110, 1);
        visibility: visible;
        position: relative;
        top: -3px;
        height: 20px;

        > span {
          opacity: 1 !important;
        }
      }
    }

    &:hover {
      > fieldset {
            border-color: rgba(72, 80, 110, 1);
      }
    }
  }
}

.Mui-focused {
  color: rgba(136, 189, 255, 1) !important;

  fieldset {
    border-color: rgba(136, 189, 255, 1) !important;
    border: 1px solid rgba(136, 189, 255, 1);

    > legend {
      color: rgba(136, 189, 255, 1) !important;
    }
  }

  &:hover {
    > fieldset {
      border-color: rgba(136, 189, 255, 1) !important;
    }
  }


  &:nth-child(2) {
    //border:2px dashed rebeccapurple;
  }
}


.PassErrorInput {
  color: rgba(255, 39, 39, 1) !important;

  > div {
    > fieldset {
      color: red !important;
      border: 1px solid rgba(255, 39, 39, 1) !important;

      > legend {
        color: red !important;
      }

      &:hover {
        border: 1px solid rgba(255, 39, 39, 1) !important;      
      }
    }
  }
}

.ErrorPass {
  position: absolute;
  bottom: -15px;
  color: rgba(255, 39, 39, 1);
  font-size: 11px;
  line-height: 15px;
  text-align: left;
}



