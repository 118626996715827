@import './variables';

@mixin break($width) {
  @media screen and (max-width: $width) {
    @content
  }
}

@mixin FLexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

@mixin FLexCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin ModalWrapper {
  width: calc(100% - 382px);
  height: 100%;
  position: absolute;
  left: 382px;
  bottom: 0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 52px;
}

@mixin FontRegular($fontSize, $lineHeight) {
  font-family: "IBMPlexSans-Regular";
  font-size: $fontSize + px;
  font-weight: 500;
  line-height: $lineHeight + px;
  text-align: left;
  color: $fontColorLight;
  transition: all 0.1s ease-in;
}

@mixin FontMedium($fontSize, $lineHeight) {
  font-family: "IBMPlexSans-Medium";
  font-size: $fontSize + px;
  font-weight: 500;
  line-height: $lineHeight + px;
  text-align: left;
  color: $fontColorLight;
  transition: all 0.1s ease-in;
}

@mixin FontSemiBold($fontSize, $lineHeight) {
  font-family: "IBMPlexSans-SemiBold";
  font-size: $fontSize + px;
  font-weight: 500;
  line-height: $lineHeight + px;
  text-align: left;
  color: $fontColorLight;
  transition: all 0.1s ease-in;
}

@mixin DefaultBtn {
  background: linear-gradient(90deg, #88BDFF 0%, #74B2FE 100%);
  padding: 15px 60px;
  border-radius: 13px;
  font-family: "IBMPlexSans-Medium";
  font-size: 20px;
  line-height: 26px;
  align-items: center;
  color: rgb(255, 255, 255, 1);

  @include break(1800px) {
    font-size: 15px;
    line-height: 22px;
    padding: 10px 35px;
  }
}

@mixin FontBold($fontSize, $lineHeight, $color) {
  font-family: "IBMPlexSans-Bold";
  font-size: $fontSize + px;
  line-height: $lineHeight + px;
  color: $color;
  text-align: left;

}

@mixin ScrollBar {
  &::-webkit-scrollbar {
    margin-left: 10px;
    height: 3px;
    width: 3px;
    border-radius: 10px;
    background-color: rgba(244, 244, 244, 0.1);
    //box-shadow: 0px 2px 10px inset rgb(22, 22, 28);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(244, 244, 244, 0.3);
    border-radius: 10px;
    //box-shadow: 0px 0px 5px 1px rgb(229, 51, 98);
    //border: 2px solid rgb(39 42 52)
  }

}

@mixin NavLinkCommon {
  width: 100%;
  @include FLexRow;
  column-gap: 15px;
  padding: 15px 20px;
  border-radius: 10px;
  @include FontSemiBold(15, 20);
  white-space: nowrap;
  margin: 5px 0;
}

@mixin WrapperStyles {
  width: 100%;
  height: 100%;
  background: $containerColorDark;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  padding: 35px 30px;
  border-radius: 20px;
  //position: relative;

  .Title {
    @include FontMedium(20, 26);
    color: rgba(255, 255, 255, 1);
    margin: 0 0 35px 0;
  }
}

@mixin CommonModalStyles {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: rgba(21, 29, 50, 0.8) !important;
  z-index: 99;
  > div {
    outline: none !important;
  }

  > div {
    &:nth-child(1) {
      background: none !important;
    }
  }
}

@mixin CommonStylesOfModalContainer {
  background: rgba(44, 51, 74, 1);
  width: 100%;

  max-height: 900px;
  max-width: 970px;
  position: relative;
  left: calc(50% + 355px);
  top: 50%;
  transform: translate(calc(-50% - 185px), -50%);
  z-index: 99999999999;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  @include FLexCol;
  align-items: center;
  gap: 35px;
  padding: 50px 40px 50px 50px;

  @include break(1800px) {
    max-width: 800px;
    padding: 35px;
    gap: 30px;
  }
}

@mixin PlaceHolderStylesCommon {
  input::placeholder {
    font-family: "IBMPlexSans-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(145, 153, 173, 1);
  }
}

@mixin CommonTableStyles {
  width: 100%;


  .TableHead {
    .TableRow {
      .TableCell {
        @include FontMedium(15, 20);
        color: rgba(243, 244, 247, 1);
        //min-width: 150px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
      }
    }
  }

  .TableBody {
    .TableRow {
      &:nth-child(2n-1) {
        background: rgba(255, 255, 255, 0.02);
      }

      .TableCell {
        border: none;
        padding: 13px 16px;

        @include break(1800px) {
          padding: 10px 16px;
        }

        .UnderLine {
          cursor: pointer;
          text-decoration: underline;
        }

        .InnerCell, .InnerCellId {
          display: flex;
          align-items: center;


          .CompanyName {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          p {
            @include FontRegular(15, 23);
            color: rgba(145, 153, 173, 1);
            white-space: nowrap;

            @include break(1800px) {
              @include FontRegular(13, 16);
              color: rgba(145, 153, 173, 1);
              white-space: wrap !important;
              word-break: break-all !important;
            }
          }

          .MoreDetails {
            white-space: nowrap !important;
            word-break: normal !important;
            text-decoration: underline;
            cursor: pointer;
          }

          .AppsImg {
            max-width: 35px;
            max-height: 35px;
            margin-right: 10px;
          }
        }

        .InnerCellId {
          justify-content: space-between;

          .Status {
            padding: 5px 15px;
            @include FontRegular(15, 20);
            text-align: center;
            border-radius: 5px;
            background: $statusColorActive;
          }

          .StatusError {
            background: $attentionColor !important;
            background: rgba(244, 91, 91, 1) !important;
          }

          .StatusStop {
            background: $statusColorStop !important;
          }

          .PendingStatus {
            background: rgba(227, 164, 0, 1);
          }

          .EditBlock {
            display: flex;
            gap: 25px;


            .Edit, .Delete {
              cursor: pointer;
            }

            .Info {
              cursor: pointer;
              margin-left: 10px;

              .HintError {
                min-width: 10px;
                max-width: 350px;
                min-height: 50px;
                max-height: 100px;
                opacity: 0;
                display: none;
              }

              &:hover {
                position: relative;

                .HintError {
                  display: block;
                  transition: all 0.3s ease-in-out;
                  opacity: 1;
                  padding: 15px;
                  border-radius: 10px;
                  background: rgba(64, 72, 104, 1);
                  position: absolute;
                  top: 20px;
                  right: 0px;
                  min-width: 10px;
                  max-width: 350px;
                  min-height: 50px;
                  max-height: 100px;

                  p {
                    @include FontRegular(13, 17);
                    word-break: normal !important;
                    white-space: nowrap !important;
                    text-align: center !important;
                    color: rgb(160, 166, 181) !important;
                  }
                }
              }
            }
          }
        }

      }
    }

    .StatisticsRow {
      .TableCell {
        padding: 14px 16px;
      }
    }

    .TotalRow {
      .TableCell {
        .InnerCell, .InnerCellId {
          p {
            @include FontRegular(15, 20);
            color: white;
          }
        }
      }
    }

    .TableRowApps {
      &:hover {
        background: linear-gradient(90deg, #88BDFF 0%, #74B2FE 100%);

        .TableCell {
          &:first-child {
            border-radius: 15px 0 0 15px !important;
          }

          &:last-child {
            border-radius: 0 15px 15px 0 !important;
          }

          .InnerCell, .InnerCellId {
            //padding: 16px !important;


            p {
              color: rgba(41, 47, 69, 1) !important;
            }


            .EditBlock {

              .Edit {
                stroke: rgba(41, 47, 69, 1) !important;


                > g {
                  > path {
                    fill: rgba(41, 47, 69, 1) !important;
                  }
                }
              }

              .Info, .Delete {
                > path {
                  fill: rgba(41, 47, 69, 0.75) !important;
                }
              }
            }

          }
        }
      }
    }
  }
}

@mixin ApplyBtn {
  background: #73ABFB;
  padding: 12.5px 30px;
  @include FontMedium(15, 19);
  border-radius: 10px;
  text-align: center;
}

@mixin CancelBtn {
  background: none;
  padding: 12.5px 30px;
  @include FontMedium(15, 19);
  border-radius: 10px;
  border: 1px solid #F3F4F7;
  text-align: center;
}

@mixin CommonPageStyles {
  width: 100%;
  height: 100%;
  @include WrapperStyles;

  .SettingsBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 24px;
    margin-bottom: 10px;

    .ApplyBtn {
      @include ApplyBtn;
    }
  }

  .Container {
    overflow: auto;
    padding-right: 10px;
    @include ScrollBar;
    width: 100%;

    .Table {
      @include CommonTableStyles;
    }
  }
}

@mixin PushTypes {
  .PushType {
    font-family: "IBMPlexSans-Regular";
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: center;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
  }
  .NewsletterPushType {
    background: #3257B8;
  }
  .ScheduledPushType {
    background: #A7A0F6;
  }

  .ScheduledPushType {
    background: #8CD7B3;
  }
  .DepositPushType {
    background: #E3A400;
  }
  .InstallationPushType {
    background: #12BE4D;
  }
  .RegistrationPushType {
    background: #9532B8;
  }
}

@mixin accordionDetails {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 450px !important;


  .BtnAddNotify {
    @include FontRegular(12, 18);

    + button {
      max-width: 200px !important;
    }
  }

  > div {
    margin: 5px 0;

    > div {
      float: left;
      max-width: 350px;
    }
  }
}

@mixin ListItems {
  @include FontRegular(15, 20);
  font-family: 'IBMPlexSans-Thin';
  font-weight: 300;
  color: rgba(237, 236, 236, 1);

  > span {
    @include FontRegular(10, 13);
    color: rgba(185, 185, 191, 1);
  }
}

@mixin HintBlock {
  width: 100%;
  padding: 20px;
  margin: 15px 0 10px 0 !important;
  border-radius: 17px;
  background: rgba(255, 255, 255, 0.1);

  > h4 {
    @include FontRegular(15, 20);
    margin-bottom: 10px;
  }

  > p {
    @include FontRegular(15, 20);
    font-family: 'IBMPlexSans-Thin';
    font-weight: 300;
    color: rgba(237, 236, 236, 1);
  }

  > ul {
    > li {
      @include ListItems;
    }
  }
}