@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";


.FormControl {
  > span {
    @include FontRegular(14, 21);
    color: rgba(145, 153, 173, 1);
  }

  .CheckBox {
    padding: 0 10px;
    color: rgba(145, 153, 173, 1)
  }
}

.Mui-checked {
  color: #88BDFF !important;
}

