@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";

.Wrapper {
  width: 100%;
  height: 100%;
  max-width: 290px;
  background: $containerColorDark;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  padding: 35px 10px 35px 20px;
  border-radius: 20px;

  .Container {
    width: 100%;
    @include FLexCol;
    justify-content: space-between;
    height: 100%;
    position: relative;
    overflow: hidden;

    .WrapperInfo {
      width: 100%;
      height: 100%;
      @include FLexCol;
      overflow: auto;
      @include ScrollBar;
      padding-right: 10px;

      .LogoBlock {
        > p {
          @include FontRegular(26, 34);
          font-weight: 600;
          text-transform: uppercase;
        }
      }

      .WalletPreview {
        width: 100%;
        border-radius: 20px;
        padding: 25px;
        @include FLexCol;
        justify-content: space-between;
        gap: 30px;
        @include FLexCol;
        background: url("../../assets/images/walletPreview.png");
        background-repeat: no-repeat;
        background-size: cover;
        margin: 30px 0 35px 0;

        .TopBlock {
          width: 100%;
          @include FLexRow;
          justify-content: space-between;

          .Name {
            @include FontSemiBold(15, 18);
            white-space: nowrap;
            cursor: pointer;
            position: relative;


              &:after {
                content: '';
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 1px;
                background: white;
                border-radius: 10px;
              }

          }

        }

        .BottomBlock {
          width: 100%;

          > p {
            @include FontRegular(12, 14);
            color: rgba(255, 255, 255, 0.5);
          }

          > div {
            width: 100%;
            @include FLexRow;
            justify-content: space-between;

            a {
              @include FontSemiBold(22, 26);
              position: relative;

              &:hover:not(:last-child) {
                &:after {
                  content: '';
                  position: absolute;
                  bottom: 2px;
                  left: 0;
                  width: 100%;
                  height: 1px;
                  background: white;
                  border-radius: 10px;
                }
              }
            }
          }
        }
      }

      .NavWrapper {
        width: 100%;

        > nav {
          width: 100%;
          @include FLexCol;
          align-items: flex-start;
          column-gap: 30px;

          > li {
            padding: 5px 20px;
            @include FontRegular(15, 20);
            margin: 5px 0;
          }

          .ListTitle {
            padding: 5px 20px;
            @include FontRegular(15, 20);
            margin-bottom: 10px;
          }

          .ItemList {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 0 !important;
            cursor: pointer;

            > span {
              display: flex;
            }

            .Icon {
              margin-right: 20px;
            }
          }

          .Link, .SubLink {
            @include NavLinkCommon;
            font-family: 'Roboto-Regular';
          }

          .SubLink {
            padding-left: 35px;
          }

          .ActiveSubLink {
            @include NavLinkCommon;
            font-family: 'Roboto-Regular';
            padding-left: 35px;
            background: rgba(115, 171, 251, 1);
          }

          .ActiveLink {
            @include NavLinkCommon;
            background: rgba(115, 171, 251, 1);
          }
        }
      }

    }


  }
}