@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";


.FormControlLabel {

  margin-right: 0 !important;
  margin-left: 0 !important;

  > span {
    &:nth-child(2) {
      @include FontRegular(15, 23);
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.Switch {
  height: 33px !important;
  width: 54px !important;
  padding: 9px !important;


  > span {
    &:first-child {
      padding: 11.5px;

      > span {


        &:nth-child(2) {
          color: #292F45 !important;
          //border: 2px solid rgba(138, 146, 166, 1);
          width: 10px;
          height: 10px;
        }
      }
    }

    &:nth-child(2) {
      border-radius: 10px;
      background: #9199AD !important;
      border: none !important;
      opacity: 1 !important;
    }
  }
}

.SwitchChecked {
  > span {
    &:nth-child(2) {
      background: rgba(119, 204, 78, 1) !important;
    }
  }
}

.LoginChecked {
  > span {
    &:nth-child(2) {
      background: rgba(115, 171, 251, 1) !important;
    }
  }
}
