@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";

.Canvas {
  @include CommonModalStyles;
  z-index: 99999999999999;
}

.Container {
  @include CommonStylesOfModalContainer;
  gap: 15px !important;
  max-width: 800px !important;
  padding: 50px;

  .Title {
    @include FontSemiBold(30, 36);
  }

  .Desc {
    @include FontRegular(15, 19);
    color: #E9E9E9;
    margin-bottom: 25px;
  }

  .ApplyBtn {
    @include ApplyBtn;
    width: 100%;
    max-width: 375px;
  }

  .CancelBtn {
    @include CancelBtn;
    width: 100%;
    max-width: 375px;
  }
}
