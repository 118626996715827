@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";

.Canvas {
  @include CommonModalStyles;
}

.Wrapper {
  padding: 50px 0;
  height: 100%;
  position: static;

  .Container {
    @include CommonStylesOfModalContainer;
    overflow: hidden;
    height: 100%;
    //border: 2px dashed orange;

    .TitleBlock {
      width: 100%;
      @include FLexRow;
      justify-content: space-between;
      padding-right: 10px;

      .Title {
        @include FontMedium(30, 36);
      }
    }

    .Form {
      width: 100%;
      @include FLexCol;
      align-items: center;
      overflow-y: auto;
      gap: 20px;
      @include ScrollBar;
      padding-right: 10px;
      padding-top: 10px;

      .SectionForm {
        width: 100%;
        @include FLexCol;
        gap: 20px;

        .SubTitle {
          @include FontRegular(15, 20);
          color: rgba(145, 153, 173, 1);
          display: flex;
          align-items: center;
          position: relative;
          margin: 0px 0 10px 20px;
          text-decoration: underline;
          text-decoration-thickness: 1px;

          .Info {
            margin-left: 5px;
          }
        }
      }

      .BtnSubmit {
        @include DefaultBtn;
      }

      .BtnSubmitDisabled {
        background: rgba(198, 223, 253, 1);
      }
    }
  }
}

.AccordionSummary {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  margin: -10px 0 !important;
  box-shadow: none !important;


  > div {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;

    &:first-child {
      border: none !important;
      min-height: 1px !important;

      > div {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  &::before {
    content: none !important;
  }
}

.AccordionDetails {
  @include accordionDetails;
  max-width: 100% !important;

  .HintBlock {
    @include HintBlock;
  }
}

.HintBlock {
  @include HintBlock;
  margin-top: 0 !important;

  .ProcentsBlock {
    display: grid;
    grid-template-rows: 2fr auto;
    grid-template-columns: 1fr auto;
    gap: 5px;
    flex-direction: row;
    column-gap: 30px;
    margin-top: 5px;

    > p {
      @include FontRegular(10, 13);
      color: rgba(185, 185, 191, 1);
    }
  }
}

