@font-face {
    font-family: 'IBMPlexSans-Regular';
    src: url("../fonts/IBMPlexSans-Regular.ttf");
}
@font-face {
    font-family: 'IBMPlexSans-Thin';
    src: url("../fonts/IBMPlexSans-Thin.ttf");
}

@font-face {
    font-family: 'IBMPlexSans-Medium';
    src: url("../fonts/IBMPlexSans-Medium.ttf");
}

@font-face {
    font-family: 'IBMPlexSans-SemiBold';
    src: url("../fonts/IBMPlexSans-SemiBold.ttf");
}

@font-face {
    font-family: 'IBMPlexSans-Bold';
    src: url("../fonts/IBMPlexSans-Bold.ttf");
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url("../fonts/Roboto-Regular.ttf");
}

*,
*::after,
*:before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

ul, li, h1, h2, h3, h4, p {
    font-family: "IBMPlexSans-Regular";
    list-style: none;
    padding: 0;
    margin: 0;
    color: rgba(0, 0, 0, 1);
    text-align: center;

}

ul[class],
ol[class] {
    padding: 0;
}

button {
    background: none;
    text-align: center;
    border: none;
    outline: none;
    padding: 0 0;
    cursor: pointer;
}

body {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    text-align: center;
    font-family: "IBMPlexSans-Regular";
    -ms-overflow-style: none;
    /*overflow: hidden;*/
    background: #151D32;
}

/*body::-webkit-scrollbar {*/
/*    width: 0;*/
/*    display: none; !*chrome*!*/
/*}*/

img {
    max-width: 100%;
    display: block;
}

input,
button,
textarea,
select {
    font: inherit;
    outline: none;
    border: none;
}

button {
    cursor: pointer;
}

input::placeholder {
    font-family: "IBMPlexSans-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: rgba(145, 153, 173, 1)
}