@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";


.FormControl {
  max-height: 51px;
  z-index: 999999999999999999999;

  > label {
    color: rgba(145, 153, 173, 1) !important;
    font-family: "IBMPlexSans-Regular";
    font-size: 14px;
    line-height: 16px;
    padding-left: 0px;
  }

  .Select {
    outline: none;
    border-radius: 17px;
    max-height: 51px;
    text-align: left;
    padding: 0 0 0 25px;
    z-index: 999999999999999999999;

    > div {
      &:first-child {
        padding: 14px 0 !important;
        font-family: 'IBMPlexSans-Regular';
        font-size: 16px;
        line-height: 20px;
        color: rgb(255, 255, 255);
        position: relative;

        &::after {
          content: url("../../assets/images/Arrow.svg");
          cursor: pointer;
          width: 10px;
          height: 10px;
          position: absolute;
          z-index: 9999999;
          top: 46%;
          left: 95%;
          transform: translate(0%, -50%);
          transition: all 0.25s ease-in;
        }
      }
    }

    > svg {
      display: none;
    }

    > fieldset {
      padding-left: 25px;
      border: 1px solid rgba(72, 80, 110, 1) !important;

      &:focus {
        > fieldset {
          padding-left: 25px;
          border: 1px solid rgba(136, 189, 255, 1) !important;
          border: none !important;
        }
      }
    }
  }

  .Mui-focused {
    > fieldset {
      padding-left: 25px !important;
      border: 1px solid rgba(136, 189, 255, 1) !important;
    }

    padding-left: 25px !important;
  }

  .SelectOpened {
    //background: yellow !important;
    border-radius: 17px 17px 0 0 !important;

    > div {
      &:first-child {

        &::after {
          z-index: 999999999999;
          left: 95%;
          transform: rotateX(180deg);
          transition: all 0.25s ease-in;
        }
      }
    }

    > fieldset {
      border-bottom: 1px solid rgba(72, 80, 110, 1) !important;
    }
  }
}


.SelectHas {
  > label {
    padding-left: 25px !important;
  }
}


.MuiList-root {
  //border: 1px solid rgba(136, 189, 255, 1) !important;
  border-radius: 0 0 17px 17px !important;
  border-top: none !important;

  > li {
    padding: 6px 20px 6px 20px;

    &:hover {
      background: rgba(44, 51, 74, 0.9) !important;

    }

    &:first-child {
      //background: yellow !important;
      padding: 20px 20px 5px 20px;
    }

    &:last-child {
      //border-radius: 0 0 17px 17px !important;
      padding: 6px 20px 20px 20px;
      border-radius: 0 !important;
    }
  }
}

.MuiMenu-paper {
  //border-radius: unset !important;
  z-index: 99999999999999;
  border-radius: 0 0 17px 17px !important;
}

.MenuItem {
  background: rgba(44, 51, 74, 1) !important;
  color: #ffffff !important;
  font-family: 'IBMPlexSans-Regular' !important;
  font-size: 14px !important;
  line-height: 18px !important;
  border: none !important;
  outline: none !important;
}

