@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";


.Wrapper {
  background: rgba(21, 29, 50, 1);
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 24px;
    left: 24px;
    width: calc(100% - 48px);
    background: url("../../assets/images/LoginBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 50%;
    border-radius: 17px;
  }

  .Form {
    width: 100%;
    max-width: 750px;
    @include FLexCol;
    gap: 10px;
    background: rgba(44, 51, 74, 1);
    box-shadow: 0px 7px 23px 0px rgba(0, 0, 0, 0.05);
    padding: 60px 50px;
    border-radius: 17px;
    z-index: 9999999;

    > div {
      &:nth-of-type(1) {
        margin-bottom: 8px;
      }

      &:nth-of-type(2) {
        margin-top: 8px;
      }
    }

    > h1 {
      @include FontMedium(30, 39);
      margin-bottom: 10px;
    }

    .BtnSubmit {
      @include FontMedium(20, 26);
      text-align: center;

      background: linear-gradient(90deg, #88BDFF 0%, #74B2FE 100%);
      border-radius: 13px;
      padding: 14px;
      width: 100%;
      max-width: 310px;
      cursor: inherit;
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .BtnSubmitDisabled {
      background: rgba(198, 223, 253, 1);
    }
  }
}