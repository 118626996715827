.TextFieldWr {
  width: 100%;
  position: relative;

  .Input {
    width: 100%;

    > label {
      color: rgba(145, 153, 173, 1) !important;
      font-family: "IBMPlexSans-Regular";
      font-size: 14px;
      line-height: 21px;
      left: 9px;
      padding-left: 0px !important;
    }

    > div {
      padding-left: 15px;
    }

    textarea {
      color: #ffffff !important;
      font-size: 14px;
      line-height: 20px;
      font-family: "IBMPlexSans-Regular";
    }

    > .Mui-focused {
      padding-left: 15px !important;
      color: rgba(136, 189, 255, 1) !important;

      > input {
        padding-left: 0px;
      }

      > fieldset {
        border: 1px solid rgba(136, 189, 255, 1) !important;
      }
    }

    .Mui-disabled {
      > input {
        -webkit-text-fill-color: #ffffff !important;
      }
    }


    > div {
      > input {
        border: none;
        background: none !important;
        border-radius: 19px;
        font-size: 16px;
        line-height: 20px;
        color: rgb(255, 255, 255);
        padding: 14px 0px;
      }

      > fieldset {
        border: 1px solid rgba(72, 80, 110, 1) !important;
        border-radius: 17px;
        padding-left: 25px;
      }
    }
  }

  .InputHas {
    > label {
      padding-left: 15px !important;
    }

    > div {
      > .Mui-focused {
        background: #a10bec;
      }
    }
  }

  .ErrorInput {

    > div {
      > fieldset {
        border: 1px solid rgba(255, 39, 39, 1) !important;
      }
    }

    > .Mui-focused {
      color: rgba(255, 39, 39, 1) !important;

      > fieldset {
        border: 1px solid rgba(255, 39, 39, 1) !important;
      }
    }
  }

  .InputDisabled {
    textarea {
      color: rgba(145, 153, 173, 1) !important;
      color: rgb(9, 64, 204) !important;
    }
  }

  .CopyBtn {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);

    &:hover {
      > svg {
        > path {
          fill: rgba(136, 189, 255, 1);
        }
      }
    }
  }

  .Error {
    position: absolute;
    bottom: -15px;
    color: rgba(255, 39, 39, 1);
    font-size: 11px;
    line-height: 15px;
  }

  .InputTextArea {
    textarea {
      color: #9199AD !important;
      font-size: 14px;
      line-height: 20px;
      font-family: "IBMPlexSans-Regular";
    }
  }

}

input:-webkit-autofill:focus {
  transition: thistle 0s 600000s, color 0s 600000s !important;
  background-color: thistle !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid green;
  -webkit-text-fill-color: rgba(145, 153, 173, 1);
  -webkit-box-shadow: 0 0 0px 1000px rgba(44, 51, 74, 1) inset;
  transition: red 5000s ease-in-out 0s;
}

.MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  color: rgba(145, 153, 173, 1) !important;
  font-family: "IBMPlexSans-Regular";
}

.MuiOutlinedInput-root {
  .Mui-disabled {
    -webkit-text-fill-color: rgba(145, 153, 173, 1) !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

//input:-webkit-autofill {
//  -webkit-box-shadow: 0 0 0px 1000px rgba(41, 47, 69, 1)  inset;
//}
input[type='number'] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
