@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";

.Canvas {
  @include CommonModalStyles;
}

.Wrapper {
  padding: 50px 0;
  height: 100%;
  position: static;

  .Container {
    @include CommonStylesOfModalContainer;
    overflow: hidden;
    height: 100%;


    .TitleBlock {
      width: 100%;
      @include FLexRow;
      justify-content: space-between;
      padding-right: 10px;

      .Title {
        @include FontMedium(30, 36);
      }
    }

    .Form {
      width: 100%;
      @include FLexCol;
      align-items: center;
      gap: 35px;
      overflow-y: auto;
      @include ScrollBar;
      padding-right: 10px;

      .SectionForm {
        width: 100%;
        @include FLexCol;
        gap: 20px;

        .SubTitle {
          @include FontRegular(15, 20);
          color: rgba(145, 153, 173, 1);
        }
      }

      .BtnSubmit {
        @include DefaultBtn;
      }
      .BtnSubmitDisabled{
        background: rgba(198, 223, 253, 1);
      }
    }
  }
}

