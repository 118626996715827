@import "../assets/styles/mixines";

.Wrapper {
  background: $backgroundColorDark;
  height: 100vh;
  //border: 1px dashed orange;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 30px;
  padding: 40px 30px 40px 30px;
  overflow: auto;
}

.Linear {
  width: 100%;
  height: 5px;
  position: fixed !important;
  top: -20px;
  z-index: 9999999999999999999 !important;
}